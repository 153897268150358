// src/firebaseInit.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyB0XsQWnnx_VVWvHKlIW7ZZTWV9ubRGmhQ",
  authDomain: "streamlingo-868c1.firebaseapp.com",
  databaseURL: "https://streamlingo-868c1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "streamlingo-868c1",
  storageBucket: "streamlingo-868c1.appspot.com",
  messagingSenderId: "180976558826",
  appId: "1:180976558826:web:e2df9be8643c921baead93"
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);


// Wenn lokal entwickelt wird, Emulatoren verwenden
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); // import { connectFunctionsEmulator } from "firebase/functions";
  const useEmulatorDatabase = false;
  if (useEmulatorDatabase) {
    connectDatabaseEmulator(rtdb, "localhost", 9000); // import { connectDatabaseEmulator } from "firebase/database";

  }
}

// exportiere 
export { auth, db, rtdb, functions, storage };
